//
// map.js
// Theme module
//

const maps = document.querySelectorAll('[data-map]');
const accessToken = 'pk.eyJ1IjoiYWx2aWUtcnMiLCJhIjoiY2w0bzQya2NuMDFnZzNmbG5hemIxZTQ2dSJ9.s-wpfcJlZl-Hfclut_aV1w';

maps.forEach((map) => {
  const elementOptions = map.dataset.map ? JSON.parse(map.dataset.map) : {};

  const defaultOptions = {
    container: map,
    style: 'mapbox://styles/mapbox/streets-v11',
    scrollZoom: false,
    interactive: false,
  };

  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  // Get access token
  mapboxgl.accessToken = accessToken;                           

  // Init map
  const mapboxMap = new mapboxgl.Map(options);

  // Add marker if specified and has all attributes
  const markerAttributes = ['marker', 'markerTitle', 'markerDescription'];

  if (markerAttributes.every(item => elementOptions.hasOwnProperty(item))) {
    const el = document.createElement('div');
    el.class = 'marker';
    el.innerHTML = '<span style="font-size: 2rem;" class="fa-solid fa-location-dot"></span>'
    new mapboxgl.Marker(el)
      .setLngLat(elementOptions.marker)
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML(
            `<h3>${elementOptions.markerTitle || ""}</h3><p>${elementOptions.markerDescription || ""}</p>`
          )
      )
      .addTo(mapboxMap);
  }
  
  mapboxMap;
});
